import React, { useEffect, useState } from 'react'
import InvestorRelationsLayout from '../../components/InvestorRelationsLayout'
import LocaleContext from '../../contexts/LocaleContext'
import Layout from '../../components/Layout'
import NewsFeed from '../../components/NewsFeed'
import { graphql } from 'gatsby'
import Menu from '../../components/Menu'
import Footer from '../../components/Footer'

export const query = graphql`
  query ($locale: String!) {
    datoCmsNewMenu ( locale: { eq: $locale }){
      ...Menu
    }
    datoCmsFooter ( locale: { eq: $locale }) {
      ...Footer
    }
  }
  `

export default function News({ data, pageContext, location }) {
  let searchParams = new URLSearchParams(location.search)
  const [contentFilter, setContentFilter] = useState(null)

  function handleFilterChange(e) {
    setContentFilter(e.target.value)
    history.pushState(null, '', e.target.value ? '?page=1&filter=' + e.target.value : '?')
    searchParams = new URLSearchParams(location.search)
  }

  useEffect(() => {
    setContentFilter(searchParams.get('filter'))
  }, [location.search]);

  return (
    <LocaleContext.Provider value={pageContext.locale || 'en'}>
      <Layout transparentMenu locale={pageContext.locale}>
        <Menu
            transparent={true}
            menu={data.datoCmsNewMenu}
            socialMedia={data.datoCmsFooter.socialMedia}
          />

        <InvestorRelationsLayout>
          <React.Fragment>
            <TranslatedTitle />
            <div className="flex flex--baseline margin-bottom">
              <Show />
              <select value={contentFilter} className="select" onChange={handleFilterChange} onBlur={handleFilterChange}>
                <TranslatedOption value="" />
                <TranslatedOption value="regulatorisk" />
                <TranslatedOption value="ovrigt" />
              </select>
            </div>
            <NewsFeed page={searchParams.get('page') || 1} filter={contentFilter} />
          </React.Fragment>
        </InvestorRelationsLayout>
        <Footer footer={data.datoCmsFooter} />
      </Layout>
    </LocaleContext.Provider>
  )
}

const TranslatedTitle = () => {
  const language = React.useContext(LocaleContext).language
  return (
    <h1>{language=='sv' ? 'Press & nyheter' : 'Press & News'}</h1>
  )
}

const Show = () => {
  const language = React.useContext(LocaleContext).language
  return (
    <span>{language=='sv' ? 'Visa:' : 'Show:'}</span>
  )
}

const TranslatedOption = (option) => {
  const language = React.useContext(LocaleContext).language
  let res = ""
  if (option.value==""){ res = <option value="">{ language=="sv" ? "Alla" : "All" }</option>;}
  else if (option.value=="regulatorisk"){ res = <option value="regulatorisk">{ language=="sv" ? "Regulatorisk" : "Regulatory" }</option>; }
  else if (option.value=="ovrigt"){ res = <option value="ovrigt">{ language=="sv" ? "Övrigt" : "Other" }</option>}

  return ( res )
}


