import React, { useEffect, useState } from 'react'
import { useFetch } from "react-async"
import { Link } from 'gatsby'

export default function NewsFeed({ page, filter }) {
  if (typeof page != 'number') page = parseInt(page) || 1
  const { isPending, error, data, run } = useFetch(`/.netlify/functions/feed?page=${page}${filter ? '&filter=' + filter : ''}`, {
    method: 'GET',
    headers: {
      "Cache-Control": "no-store"
    }
  })
  const [feed, setFeed] = useState(null);

  useEffect(() => {
    run()
  }, [filter]);

  if (isPending) return "Loading..."
  if (error) return <div>The feed couldn't be loaded, please check again later</div>

  if (data) {
    if (!data.bodyUsed) { data.json().then(setFeed) }
    if (!feed) return <div></div>
    const pages = Math.ceil(feed.TotalFoundReleases / feed.PageSize)
    return (
      <React.Fragment>
        <table>
          <tbody>
            {feed.Releases.map(Release => (
              <tr className="" key={Release.Id}>
                <td style={{ whiteSpace: 'nowrap' }}>{Release.PublishDate.slice(0, 10)}</td>
                <td><Link to={`/investerarrelationer/press-nyheter/nyhet?id=${Release.EncryptedId}`}>{Release.Title}</Link></td>
                <td>{Release.IsRegulatory ? 'Regulatorisk' : 'Övrigt'}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="pagination flex--h-center">
          {page > 4 && (
            <React.Fragment>
              <Link to={`/investerarrelationer/press-nyheter${filter ? '?filter=' + filter : ''}`} className="pagination__item pagination__first">⯬</Link>
              <div className="pagination__item">...</div>
            </React.Fragment>
          )}
          {page === 4 && <Link to={`/investerarrelationer/press-nyheter?page=1${filter ? '&filter=' + filter : ''}`} className="pagination__item">1</Link>}
          {page > 2 && <Link to={`/investerarrelationer/press-nyheter?page=${page - 2}${filter ? '&filter=' + filter : ''}`} className="pagination__item">{page - 2}</Link>}
          {page > 1 && <Link to={`/investerarrelationer/press-nyheter?page=${page - 1}${filter ? '&filter=' + filter : ''}`} className="pagination__item">{page - 1}</Link>}
          <div className="pagination__item pagination__item--active">{page}</div>
          {page < pages && <Link to={`/investerarrelationer/press-nyheter?page=${page + 1}${filter ? '&filter=' + filter : ''}`} className="pagination__item">{page + 1}</Link>}
          {page + 1 < pages && <Link to={`/investerarrelationer/press-nyheter?page=${page + 2}${filter ? '&filter=' + filter : ''}`} className="pagination__item">{page + 2}</Link>}
          {page + 2 === pages && <Link to={`/investerarrelationer/press-nyheter?page=${page + 3}${filter ? '&filter=' + filter : ''}`} className="pagination__item">{page + 3}</Link>}
          {page + 3 < pages && (
            <React.Fragment>
              <div className="pagination__item">...</div>
              <Link to={`/investerarrelationer/press-nyheter?page=${pages}${filter ? '&filter=' + filter : ''}`} className="pagination__item pagination__last">⯮</Link>
            </React.Fragment>
          )}
        </div>
      </React.Fragment>
    )
  }
}
